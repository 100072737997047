import { lazy, Suspense } from "react";
import { createBrowserRouter } from "react-router-dom";

import { App } from "components/App";

import { MainPageContextProvider } from "features/main-page";

import { ProtectedRoute } from "components/common/ProtectedRoute";
import { Loader } from "components/UI/Loader";

const IndexPage = lazy(() => import("pages/IndexPage"));
const CatalogPage = lazy(() => import("pages/CatalogPage"));
const ProductDetailsPage = lazy(() => import("pages/ProductDetailsPage"));
const DeliveryPage = lazy(() => import("pages/DeliveryPage"));
const AboutPage = lazy(() => import("pages/AboutPage"));
const BlogPage = lazy(() => import("pages/BlogPage"));
const BlogDetailsPage = lazy(() => import("pages/BlogDetailsPage"));
const LoyaltyPage = lazy(() => import("pages/LoyaltyPage"));
const ContactsPage = lazy(() => import("pages/Contacts"));
const FaqPage = lazy(() => import("pages/FaqPage"));
const CartPage = lazy(() => import("pages/CartPage"));
const FavoritesPage = lazy(() => import("pages/FavoritesPage"));
const HistoryPage = lazy(() => import("pages/HistoryPage"));
const MyPage = lazy(() => import("pages/MyPage"));

import { NotFound } from "pages/NotFound";
import { OrderFail } from "pages/OrderFail";
import { OrderSuccess } from "pages/OrderSuccess";
import { ErrorSection } from "components/pages/Error/ErrorSection/ErrorSectionRoot";

export const router = createBrowserRouter([
	{
		path: "/",
		element: <App />,
		errorElement: <ErrorSection />,
		children: [
			{
				index: true,
				element: (
					<Suspense fallback={<Loader />}>
						<MainPageContextProvider>
							<IndexPage />
						</MainPageContextProvider>
					</Suspense>
				),
				// lazy: async () => {
				// 	const { IndexPage } = await import("pages/IndexPage");

				// 	return { Component: IndexPage };
				// },
			},
			{
				path: "cart",
				element: (
					<Suspense fallback={<Loader />}>
						<CartPage />
					</Suspense>
				),
			},
			{
				path: "favorites",
				element: (
					<Suspense fallback={<Loader />}>
						<FavoritesPage />
					</Suspense>
				),
			},
			{
				path: "catalog",
				children: [
					{
						index: true,
						element: (
							<Suspense fallback={<Loader />}>
								<CatalogPage />
							</Suspense>
						),
					},
					{
						path: ":productId",
						element: (
							<Suspense fallback={<Loader />}>
								<ProductDetailsPage />
							</Suspense>
						),
					},
				],
			},
			{
				path: "delivery",
				element: (
					<Suspense fallback={<Loader />}>
						<DeliveryPage />
					</Suspense>
				),
			},
			{
				path: "about",
				element: (
					<Suspense fallback={<Loader />}>
						<AboutPage />
					</Suspense>
				),
			},
			{
				path: "blog",
				children: [
					{
						index: true,
						element: (
							<Suspense fallback={<Loader />}>
								<BlogPage />
							</Suspense>
						),
					},
					{
						path: ":blogId",
						element: (
							<Suspense fallback={<Loader />}>
								<BlogDetailsPage />
							</Suspense>
						),
					},
				],
			},
			{
				path: "loyalty",
				element: (
					<Suspense fallback={<Loader />}>
						<LoyaltyPage />
					</Suspense>
				),
			},
			{
				path: "contacts",
				element: (
					<Suspense fallback={<Loader />}>
						<ContactsPage />
					</Suspense>
				),
			},
			{
				path: "faq",
				element: (
					<Suspense fallback={<Loader />}>
						<FaqPage />
					</Suspense>
				),
			},
			{
				path: "my",
				children: [
					{
						index: true,
						element: (
							<ProtectedRoute redirectToPath="/">
								<Suspense fallback={<Loader />}>
									<MyPage />
								</Suspense>
							</ProtectedRoute>
						),
					},
					{
						path: "history",
						element: (
							<ProtectedRoute redirectToPath="/">
								<Suspense fallback={<Loader />}>
									<HistoryPage />
								</Suspense>
							</ProtectedRoute>
						),
					},
				],
			},
			{ path: "orderSuccess", element: <OrderSuccess /> },
			{ path: "orderFail", element: <OrderFail /> },
			{
				path: "*",
				element: <NotFound />,
			},
		],
	},
]);
