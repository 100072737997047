import { useBodyOverflow } from "hooks/useBodyOverflow";
import { useScrollToTop } from "hooks/useScrollToTop";

import { ErrorNotificationProps } from "./types";

import { ReactComponent as Plus } from "assets/vectors/plus-dark.svg";
import { useEffect } from "react";

function ErrorNotification({ messages, onClose }: ErrorNotificationProps) {
	useBodyOverflow({ active: true });
	useScrollToTop();

	useEffect(() => {
		return () => {
			onClose();
		};
	}, [onClose]);

	return (
		<div className="popup popup--recovery-code active">
			<button
				className="close-cross"
				onClick={onClose}
			>
				<Plus />
			</button>
			<div className="popup__wrap">
				<div className="popup__head">
					<div className="section-title">
						<span className="section-title__text">ошибка</span>
					</div>

					<div className="popup__block">
						{messages.map((message, index) => (
							<span key={`error-message-${index}`} className="popup__block-text">
								{message}
							</span>
						))}
					</div>
				</div>

				<div className="popup__body">
					<div className="popup-form__btns">
						<button type="button" className="btn secondary" onClick={onClose}>
							ОК
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}

export { ErrorNotification };
