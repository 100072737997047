import parse from "html-react-parser";

import { parserOptions } from "utils/react-html-parser";

import { NewsTextProps } from "./types";

import "./NewsText.styles.scss";

function NewsText({ elementData }: NewsTextProps) {
	const { text } = elementData;

	return (
		<div className="news-block news-block--left">
			<div className="news-block__row">
				<div>{text && parse(text, parserOptions)}</div>
			</div>
		</div>
	);
}

export { NewsText };
