import React, { ErrorInfo } from "react";
import ReactDOM from "react-dom/client";

import { RouterProvider } from "react-router-dom";

import { router } from "routes";

import { ErrorSection } from "components/pages/Error/ErrorSection/ErrorSectionRoot";

import { SeoContextProvider } from "features/seo";
import { SiteOptionsContextProvider } from "features/site-options";
import { BurgerContextProvider } from "features/burger";
import { FilterContextProvider } from "features/filter";
import { CartContextProvider } from "features/cart";
import {
	AuthContextProvider,
	AuthModalContextProvider,
	RegistrationContextProvider,
	ForgotPasswordContextProvider,
} from "features/auth";
import { FavoritesContextProvider } from "features/favorites";

import "normalize.css";
import "styles/index.scss";

const rootContainer = document.getElementById("root") as HTMLDivElement;
const root = ReactDOM.createRoot(rootContainer);

interface ErrorBoundaryState {
	hasError: boolean;
}
interface Props {
	children: React.ReactNode;
	needJSX: boolean;
}

class ErrorBoundary extends React.Component<Props, ErrorBoundaryState> {
	constructor(props: Props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError() {
		return { hasError: true };
	}

	componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		console.error(`Error:`, error, errorInfo.componentStack);
	}

	render() {
		if (this.state.hasError) {
			return <ErrorSection />;
		}

		return this.props.children;
	}
}

root.render(
	<React.StrictMode>
		<ErrorBoundary needJSX={true}>
			<SeoContextProvider>
				<SiteOptionsContextProvider>
					<AuthContextProvider>
						<AuthModalContextProvider>
							<CartContextProvider>
								<FavoritesContextProvider>
									<FilterContextProvider>
										<BurgerContextProvider>
											<ForgotPasswordContextProvider>
												<RegistrationContextProvider>
													<RouterProvider router={router} />
												</RegistrationContextProvider>
											</ForgotPasswordContextProvider>
										</BurgerContextProvider>
									</FilterContextProvider>
								</FavoritesContextProvider>
							</CartContextProvider>
						</AuthModalContextProvider>
					</AuthContextProvider>
				</SiteOptionsContextProvider>
			</SeoContextProvider>
		</ErrorBoundary>
	</React.StrictMode>,
);
