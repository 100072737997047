import { NewsImageProps } from "./types";

import "./NewsImage.styles.scss";

function NewsImage({ elementData }: NewsImageProps) {
	const { image } = elementData;

	const isImageExist = !!image;

	return (
		<div className="news-block news-block--left">
			<div className="news-block__row">
				{isImageExist && <img src={image.file ?? ""} alt="" />}
			</div>
		</div>
	);
}

export { NewsImage };
