import { Element, DOMNode, domToReact } from "html-react-parser";

import { Link } from "components/common/Link";

export const parserOptions = {
	replace(domNode: DOMNode) {
		const node = domNode as Element;

		if (node.attribs) {
			const isReactLink = typeof node.attribs["data-react-link"] === "string";

			if (isReactLink) {
				const href = node.attribs.href;
				const className = node.attribs.classname;
				const styles = node.attribs.style;

				return (
					<Link
						to={href}
						autoScrollable={true}
						nav={false}
						classes={className}
						styles={styles}
					>
						{domToReact(node.children)}
					</Link>
				);
			}
		}
	},
};
