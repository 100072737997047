import parse from "html-react-parser";

import { parserOptions } from "utils/react-html-parser";

import { NewsImageRightProps } from "./types";

import "./NewsImageRight.styles.scss";

function NewsImageRight({ elementData }: NewsImageRightProps) {
	const { image, text, text2 } = elementData;

	const isImageExist = !!image;
	const isAdditionalTextExist = !!text2;

	return (
		<div className="news-block">
			<div className="news-block__row">
				<div className="news-block__col">
					{text && parse(text, parserOptions)}
				</div>
				<div className="news-block__col">
					{isImageExist && <img src={image.file ?? ""} alt="" />}
					{!isImageExist && isAdditionalTextExist && (
						<div>{parse(text2, parserOptions)}</div>
					)}
				</div>
			</div>
		</div>
	);
}

export { NewsImageRight };
