import { Link } from "components/common/Link";

import { ReactComponent as Eye } from "assets/vectors/eye.svg";

import "./ErrorSectionRoot.styles.scss";

function ErrorSection() {
	return (
		<section className="error-section">
			<div className="container">
				<div className="error-wrap">
					<div className="error-wrap__row">
						<div className="error-wrap__col">
							<div className="error">
								<span className="error__title">???</span>
							</div>
						</div>

						<div className="error-wrap__col">
							<div className="error">
								<span className="error__text">
									Неизвестная ошибка. Попробуйте ещё.
								</span>

								<Link
									to="/"
									autoScrollable={true}
									nav={false}
									classes="sub-link"
								>
									<Eye />
									<span>Главная</span>
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
}

export { ErrorSection };