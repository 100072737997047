import parse from "html-react-parser";

import { parserOptions } from "utils/react-html-parser";

import { NewsImageLeftProps } from "./types";

import "./NewsImageLeft.styles.scss";

function NewsImageLeft({ elementData }: NewsImageLeftProps) {
	const { image, text, text2 } = elementData;

	const isImageExist = !!image;
	const isAdditionalTextExist = !!text2;

	return (
		<div className="news-block news-block--left">
			<div className="news-block__row">
				<div className="news-block__col">
					{isImageExist && <img src={image.file ?? ""} alt="" />}
					{!isImageExist && isAdditionalTextExist && (
						<div className="1233">{parse(text2, parserOptions)}</div>
					)}
				</div>
				<div className="news-block__col">
					<div>{text && parse(text, parserOptions)}</div>
				</div>
			</div>
		</div>
	);
}

export { NewsImageLeft };
