import {
	useContext,
	useState,
	useEffect,
	useImperativeHandle,
	useRef,
	forwardRef,
	useMemo,
	FormEvent,
	useCallback,
} from "react";
import { createPortal } from "react-dom";

import cn from "classnames";
import { useNavigate } from "react-router-dom";

import ReactSelect, { SingleValue, SelectInstance } from "react-select";
import InputMask from "react-input-mask";

import {
	useRequest,
	// getDeliveryTypes,
	getDeliveryReceiptTypes,
	getCdekRegions,
	getCdekCities,
	getDeliveryCompanies,
	getCdekDeliveryPoints,
	calculateCdekDeliveryCost,
	calculatePostRussiaDeliveryCost,
	makeOrder,
	OptionData,
	CdekOptionData,
	CdekDeliveryPointData,
	ServerValidationError,
	isSuccessCardOrderPredicate,
	isSuccessOrderPredicate,
	isResponseValidateErrorPredicate,
} from "api";

import { authContext } from "features/auth";
import { cartContext } from "features/cart";
import { siteOptionsContext } from "features/site-options";

// import { useTab } from "hooks/useTab";

import { ErrorNotification } from "../../ErrorNotification";

import { OptionsTab } from "../../OptionsTab";

import { Overlay } from "components/UI/Overlay";
import { Input, useInput } from "components/UI/Input";

import {
	DELIVERY_DEPENDENCIES,
	LOCAL_DELIVERY_CITY_CODES,
	FREE_DELIVERY_DEPENDENCIES,
} from "features/cart/data/constants";

import { DeliveryStepProps, DeliveryStepRef } from "./types";

import "./DeliveryStep.styles.scss";

const DeliveryStep = forwardRef<DeliveryStepRef, DeliveryStepProps>(
	({ fetchedProducts }, ref) => {
		const formRef = useRef<HTMLFormElement>(null);
		const regionSelectRef = useRef<SelectInstance<{
			value: number;
			label: string;
		}> | null>(null);
		const citySelectRef = useRef<SelectInstance<{
			value: number;
			label: string;
		}> | null>(null);
		const officeSelectRef = useRef<SelectInstance<{
			value: string;
			label: string;
		}> | null>(null);

		const [errorNoticationVisible, setErrorNotificationVisible] = useState<boolean>(false);

		const [serverValidationErrors, setServerValidationErrors] =
			useState<ServerValidationError>({ errors: {} });

		// const [activeDeliveryTypeId, setActiveDeliveryTypeId] = useState(1);
		// const [activeDeliveryReceiptTypeId, setActiveDeliveryReceiptTypeId] =
		// 	useState(1);
		const [selectedRegionCode, setSelectedRegionCode] = useState<null | number>(
			null,
		);
		const [selectedRegionName, setSelectedRegionName] = useState<null | string>(
			null,
		);
		const [selectedCityCode, setSelectedCityCode] = useState<null | number>(
			null,
		);
		const [selectedCityName, setSelectedCityName] = useState<null | string>(
			null,
		);
		const [selectedOfficeCode, setSelectedOfficeCode] = useState<null | string>(
			null,
		);

		const [regionTouched, setRegionTouched] = useState(false);
		const [cityTouched, setCityTouched] = useState(false);
		const [officeTouched, setOfficeTouched] = useState(false);

		const [deliveryCompanies, setDeliveryCompanies] = useState<OptionData[]>(
			[],
		);
		// const [deliveryTypes, setDeliveryTypes] = useState<OptionData[]>([]);
		const [deliveryReceiptTypes, setDeliveryReceiptTypes] = useState<
			OptionData[]
		>([]);
		const [regions, setRegions] = useState<CdekOptionData[]>([]);
		const [cities, setCities] = useState<CdekOptionData[]>([]);
		const [offices, setOffices] = useState<CdekDeliveryPointData[]>([]);

		const [deliveryPointCode, setDeliveryPointCode] = useState<string | null>(
			null,
		);
		const [deliveryPointAddress, setDeliveryPointAddress] = useState<
			string | null
		>(null);

		const { request } = useRequest();
		const navigate = useNavigate();

		const { siteOptions } = useContext(siteOptionsContext);

		const { authenticated, authToken, userData } = useContext(authContext);
		const {
			freeDeliveryOptionName,
			cartProducts,
			deliveryTypeId,
			deliveryCompanyId,
			deliveryCost,
			paymentOptionId,
			promocode,
			promocodeDiscount,
			freeDeliveryFrom,
			onChangeDeliveryTypeId,
			onChangeDeliveryCompanyId,
			// onChangeDeliveryCityCode,
			// onChangeDeliveryPrice,
			onChangeFreeDeliveryFrom,
			onChangeDeliveryCost,
			onChangePromocode,
			onChangePromocodeDiscount,
			onChangeProcessingOrder,
			onChangeFreeDeliveryOptionName,
		} = useContext(cartContext);

		// const {
		// 	activeTabIndex: activeDeliveryCompanyId,
		// 	changeActiveTabIndexHandler: changeActiveDeliveryCompanyIdHandler,
		// } = useTab({
		// 	initIndex: 1,
		// });

		useImperativeHandle(ref, () => {
			return {
				submit: () => {
					if (formRef.current) {
						formRef.current.dispatchEvent(
							new Event("submit", { cancelable: true, bubbles: true }),
						);
					}
				},
			};
		});

		// const totalOrderPrice = cartProducts.reduce((prev, product) => {
		// 	if (!fetchedProducts.length) return 0;

		// 	const foundProductIndex = fetchedProducts.findIndex(
		// 		(fetchedProduct) => fetchedProduct.id === product.productId,
		// 	);

		// 	if (foundProductIndex !== -1) {
		// 		const foundProduct = fetchedProducts[foundProductIndex];

		// 		const foundCatalogProductIndex = foundProduct.items.findIndex(
		// 			(catalogProduct) => catalogProduct.id === product.catalogProductId,
		// 		);

		// 		if (foundCatalogProductIndex !== -1) {
		// 			const foundCatalogProduct =
		// 				foundProduct.items[foundCatalogProductIndex];
		// 			const price = foundCatalogProduct.price * product.count;

		// 			return prev + price;
		// 		}
		// 	}

		// 	return 0;
		// }, 0);

		const [totalOrderProductsWeight, totalOrderPrice] = cartProducts.reduce(
			(prev, product) => {
				if (!fetchedProducts.length) return [0, 0];

				const foundProductIndex = fetchedProducts.findIndex(
					(fetchedProduct) => fetchedProduct.id === product.productId,
				);

				if (foundProductIndex !== -1) {
					const foundProduct = fetchedProducts[foundProductIndex];

					const foundCatalogProductIndex = foundProduct.items.findIndex(
						(catalogProduct) => catalogProduct.id === product.catalogProductId,
					);

					if (foundCatalogProductIndex !== -1) {
						const foundCatalogProduct =
							foundProduct.items[foundCatalogProductIndex];
						const formattedWeight = parseInt(foundCatalogProduct.weight.title);
						const weight = formattedWeight * product.count;
						const price = foundCatalogProduct.price * product.count;

						return [prev[0] + weight, prev[1] + price];
					}
				}

				return [0, 0];
			},
			[0, 0],
		);

		// useEffect(() => {
		// 	const fetchDeliveryTypes = async () => {
		// 		const result = await request({
		// 			service: getDeliveryTypes,
		// 		});

		// 		if (result) {
		// 			setDeliveryTypes(result);
		// 		}
		// 	};

		// 	fetchDeliveryTypes();
		// }, [request]);

		useEffect(() => {
			const fetchDeliveryCompanies = async () => {
				const result = await request({
					service: getDeliveryCompanies,
				});

				if (result) {
					setDeliveryCompanies(result);
				}
			};

			fetchDeliveryCompanies();
		}, [request]);

		useEffect(() => {
			const fetchDeliveryReceiptTypes = async () => {
				const result = await request({
					service: getDeliveryReceiptTypes,
				});

				if (result) {
					setDeliveryReceiptTypes(result);
				}
			};

			fetchDeliveryReceiptTypes();
		}, [request]);

		useEffect(() => {
			const fetchCdekRegions = async () => {
				const result = await request({
					service: getCdekRegions,
				});

				if (result) {
					setRegions(result);
				}
			};

			fetchCdekRegions();
		}, [request]);

		useEffect(() => {
			if (!selectedRegionCode) return;

			const fetchCdekCities = async () => {
				const result = await request({
					service: () => getCdekCities({ regionId: selectedRegionCode }),
				});

				if (result) {
					setCities(result);
				}

				// if (result && !isResponseValidateErrorPredicate(result)) {
				// 	setCities(result);
				// 	setServerValidationErrors((prevState) => {
				// 		return {
				// 			errors: { ...prevState.errors, postcode: [] },
				// 		};
				// 	});
				// }
				// if (result && isResponseValidateErrorPredicate(result)) {
				// 	setServerValidationErrors({ errors: result.errors });
				// }
			};

			fetchCdekCities();
		}, [request, selectedRegionCode]);

		useEffect(() => {
			if (!selectedCityCode) return;

			const fetchCdekOffices = async () => {
				const result = await request({
					service: () => getCdekDeliveryPoints({ cityId: selectedCityCode }),
				});

				// if (result) {
				// 	setOffices(result);
				// }

				if (result && !isResponseValidateErrorPredicate(result)) {
					setOffices(result);
					setServerValidationErrors((prevState) => {
						return {
							errors: { ...prevState.errors, cdekCity: [] },
						};
					});
				} else if (result && isResponseValidateErrorPredicate(result)) {
					setServerValidationErrors({ errors: result.errors });
				}
			};

			fetchCdekOffices();
		}, [request, selectedRegionCode, selectedCityCode]);

		// useEffect(() => {
		// 	let discountPrice = 0;

		// 	switch (activeDeliveryTypeId) {
		// 		case 1:
		// 			discountPrice =
		// 				totalOrderPrice >= 1500 ? 0 : DELIVERY_PRICES[activeDeliveryTypeId];
		// 			break;
		// 		case 2:
		// 			discountPrice =
		// 				totalOrderPrice >= 3000 ? 0 : DELIVERY_PRICES[activeDeliveryTypeId];
		// 			break;
		// 	}

		// 	onChangeDeliveryPrice({ price: discountPrice });
		// }, [onChangeDeliveryPrice, activeDeliveryTypeId, totalOrderPrice]);

		const formattedRegions = regions.map((region) => ({
			value: region.code,
			label: region.label,
		}));

		const formattedCities = cities.map((city) => ({
			value: city.code,
			label: city.label,
		}));

		const formattedOficces = offices.map((office) => ({
			value: office.code,
			label: `${office.label} (${office.address})`,
		}));

		const {
			value: firstNameValue,
			errorVisible: firstNameErrorVisible,
			invalid: firstNameInvalid,
			onChangeValue: onFirstNameValueChange,
			onBlur: onFirstNameBlur,
			onTouch: onFirstNameTouch,
		} = useInput({
			checks: [
				{
					reverseCheck: false,
					regexp: /^.+$/i,
					errorMessage: "Поле Имя является обязательным.",
				},
			],
			serverValidationErrors: [],
		});

		const {
			value: lastNameValue,
			errorVisible: lastNameErrorVisible,
			invalid: lastNameInvalid,
			onChangeValue: onLastNameValueChange,
			onBlur: onLastNameBlur,
			onTouch: onLastNameTouch,
		} = useInput({
			checks: [
				{
					reverseCheck: false,
					regexp: /^.+$/i,
					errorMessage: "Поле Фамилия является обязательным.",
				},
			],
			serverValidationErrors: [],
		});

		const {
			value: phoneValue,
			errorVisible: phoneErrorVisible,
			invalid: phoneInvalid,
			onChangeValue: onPhoneValueChange,
			onBlur: onPhoneBlur,
			onTouch: onPhoneTouch,
		} = useInput({
			checks: [
				{
					reverseCheck: false,
					regexp: /^\+7 \d{3} \d{3}-\d{2}-\d{2}/i,
					errorMessage: "Поле Телефон является обязательным.",
				},
			],
			serverValidationErrors: [],
		});

		const {
			value: emailValue,
			errorVisible: emailErrorVisible,
			invalid: emailInvalid,
			onChangeValue: onEmailValueChange,
			onBlur: onEmailBlur,
			onTouch: onEmailTouch,
		} = useInput({
			checks: [
				{
					reverseCheck: false,
					regexp: /(.+)@(.+).(.+)/i,
					errorMessage: "Поле Email является обязательным.",
				},
			],
			serverValidationErrors: [],
		});

		const {
			value: postcodeValue,
			errorVisible: postcodeErrorVisible,
			errors: postcodeErrors,
			invalid: postcodeInvalid,
			onChangeValue: onPostcodeValueChange,
			onBlur: onPostcodeBlur,
			onTouch: onPostcodeTouch,
		} = useInput({
			checks: [
				{
					reverseCheck: false,
					regexp: /^.+$/i,
					errorMessage: "Поле Почтовый индекс является обязательным.",
				},
				{
					reverseCheck: false,
					regexp: /^\d{6}$/i,
					errorMessage: "Поле Почтовый индекс должно быть 6 цифр.",
				},
			],
			serverValidationErrors: serverValidationErrors.errors["postcode"],
		});

		const {
			value: addressValue,
			errorVisible: addressErrorVisible,
			invalid: addressInvalid,
			onChangeValue: onAddressValueChange,
			onBlur: onAddressBlur,
			onTouch: onAddressTouch,
		} = useInput({
			checks: [
				{
					reverseCheck: false,
					regexp: /^.+$/i,
					errorMessage: "Поле Адрес является обязательным.",
				},
			],
			serverValidationErrors: [],
		});

		const { value: commentValue, onChangeValue: onCommentValueChange } =
			useInput({
				checks: [],
				serverValidationErrors: [],
			});

		const userDataInvalid =
			firstNameInvalid || lastNameInvalid || phoneInvalid || emailInvalid;
		// const formIsInvalid =
		// 	postcodeInvalid ||
		// 	addressInvalid ||
		// 	!selectedRegionCode ||
		// 	!selectedCityCode ||
		// 	(!authenticated ? userDataInvalid : false);

		const isPostRussiaDelivery = deliveryCompanyId === 2;
		const isCdekCourierDelivery =
			deliveryCompanyId === 1 && deliveryTypeId === 2;
		const isCdekOfficeDelivery =
			deliveryCompanyId === 1 && deliveryTypeId === 1;

		const filteredDeliveryCompanies = deliveryCompanies.filter((company) => {
			const dependency = DELIVERY_DEPENDENCIES[deliveryTypeId];

			return dependency.deliveryCompanyIds.includes(company.id);
		});

		const freeDeliveryConfig = useMemo(() => FREE_DELIVERY_DEPENDENCIES.find((dep) => {
			if (selectedCityCode && dep.cities.includes(selectedCityCode)) {
				return (
					dep.cities.includes(selectedCityCode) &&
					dep.deliveryMethodIds.includes(deliveryTypeId)
				);
			}

			if (selectedCityCode && !dep.cities.includes(selectedCityCode)) {
				return (
					dep.deliveryMethodIds.includes(deliveryTypeId) &&
					dep.cities.length === 0
				);
			}

			if (!selectedCityCode) {
				return (
					dep.deliveryMethodIds.includes(deliveryTypeId) &&
					dep.cities.length === 0
				);
			}

			return false;
		}), [selectedCityCode, deliveryTypeId]);

		useEffect(() => {
			if (typeof freeDeliveryConfig?.priceFrom === "string") {
				onChangeFreeDeliveryOptionName({ optionName: freeDeliveryConfig?.priceFrom });
			}
			else {
				onChangeFreeDeliveryOptionName({ optionName: null });
			}
		}, [freeDeliveryConfig, onChangeFreeDeliveryOptionName]);

		const freeShippingStartPrice = siteOptions.find(
			(option) => option.key === freeDeliveryOptionName,
		)?.value;

		// let discountSum = 0;
		let isFreeDelivery = false;

		if (freeDeliveryFrom) {
			isFreeDelivery = totalOrderPrice >= freeDeliveryFrom;
		}

		if (freeShippingStartPrice && !freeDeliveryFrom && freeDeliveryFrom !== 0) {
			isFreeDelivery = totalOrderPrice >= +freeShippingStartPrice;
		}

		let loyaltyDiscount = 1;
		let personalDiscount = 1;

		// const promoDiscount =
		// 	((totalOrderPrice + (isFreeDelivery ? 0 : deliveryCost)) / 100) *
		// 	promocodeDiscount;

		// if (userData) {
		// 	loyaltyDiscount =
		// 		((totalOrderPrice +
		// 			(isFreeDelivery ? 0 : deliveryCost) -
		// 			promoDiscount) /
		// 			100) *
		// 		userData.loyalty.discount;
		// }

		// const discountSum = Math.round(loyaltyDiscount + promoDiscount);
		// const totalCost =
		// 	totalOrderPrice + (isFreeDelivery ? 0 : deliveryCost) - discountSum;

		if (userData) {
			loyaltyDiscount = 1 - userData.loyalty.discount / 100;
		}
		const promoDiscount = 1 - promocodeDiscount / 100;

		if (userData && userData.personalDiscount) {
			personalDiscount = 1 - userData.personalDiscount / 100;
		}

		const maxDiscount = Math.min(loyaltyDiscount, promoDiscount, personalDiscount);

		// const totalDiscount = loyaltyDiscount * promoDiscount * personalDiscount;

		const discountSum = +(
			totalOrderPrice -
			totalOrderPrice * maxDiscount
		).toFixed(2);
		const totalCost =
			totalOrderPrice - discountSum + (isFreeDelivery ? 0 : deliveryCost);

		useEffect(() => {
			if (freeDeliveryConfig && typeof freeDeliveryConfig.priceFrom === "number") {
				onChangeFreeDeliveryFrom({
					price: freeDeliveryConfig.priceFrom
						? freeDeliveryConfig.priceFrom
						: 0,
				});
			}
		}, [freeDeliveryConfig, onChangeFreeDeliveryFrom]);

		useEffect(() => {
			const dependency = DELIVERY_DEPENDENCIES[deliveryTypeId];

			const isDeliveryCompanyAvailable =
				dependency.deliveryCompanyIds.includes(deliveryCompanyId);

			if (isDeliveryCompanyAvailable) return;

			onChangeDeliveryCompanyId({ id: dependency.deliveryCompanyIds[0] });
		}, [deliveryTypeId, deliveryCompanyId, onChangeDeliveryCompanyId]);

		// useEffect(() => {
		// 	if (selectedCityCode) {
		// 		onChangeDeliveryCityCode({ code: selectedCityCode });
		// 	}
		// }, [selectedCityCode, onChangeDeliveryCityCode]);

		// const changeDeliveryTypeHandler = (id: number) => {
		// 	setActiveDeliveryTypeId(id);
		// 	setActiveDeliveryReceiptTypeId(1);
		// };

		// const changeDeliveryReceiptTypeHandler = (id: number) => {
		// 	setActiveDeliveryReceiptTypeId(id);
		// };

		useEffect(() => {
			if (deliveryCompanyId !== 1 || !selectedCityCode) return;

			const fetchCdekDeliveryCost = async () => {
				const result = await request({
					service: () =>
						calculateCdekDeliveryCost({
							weight: [totalOrderProductsWeight],
							deliveryReceiptType: [deliveryTypeId],
							cdekCityCode: [selectedCityCode],
							address: [addressValue],
						}),
				});

				if (result && !isResponseValidateErrorPredicate(result)) {
					onChangeDeliveryCost({ cost: Math.ceil(result.totalSum) });
					setServerValidationErrors((prevState) => {
						return {
							errors: { ...prevState.errors, postcode: [] },
						};
					});
					return;
				} else if (result && isResponseValidateErrorPredicate(result)) {
					onChangeDeliveryCost({ cost: 0 });
					// setServerValidationErrors({ errors: result.errors });
					setServerValidationErrors((prevState) => {
						return {
							errors: { ...prevState.errors, postcode: result.errors.postcode },
						};
					});
					setErrorNotificationVisible(true);
				}

				// if (result) {
				// 	onChangeDeliveryCost({ cost: Math.ceil(result.totalSum) });
				// }
			};

			fetchCdekDeliveryCost();
		}, [
			request,
			selectedCityCode,
			deliveryCompanyId,
			deliveryTypeId,
			totalOrderProductsWeight,
			addressValue,
			onChangeDeliveryCost,
		]);

		useEffect(() => {
			if (deliveryCompanyId !== 2 || postcodeValue.length !== 6) return;

			const fetchPostRussiaDeliveryCost = async () => {
				const result = await request({
					service: () =>
						calculatePostRussiaDeliveryCost({
							weight: [totalOrderProductsWeight],
							postcodeTo: [postcodeValue],
						}),
				});

				if (result && !isResponseValidateErrorPredicate(result)) {
					onChangeDeliveryCost({ cost: Math.ceil(result.deliverySum) });
					setServerValidationErrors((prevState) => {
						return {
							errors: { ...prevState.errors, postcode: [] },
						};
					});
					return;
				} else if (result && isResponseValidateErrorPredicate(result)) {
					onChangeDeliveryCost({ cost: 0 });
					setServerValidationErrors({ errors: result.errors });
					setErrorNotificationVisible(true);
				}
			};

			fetchPostRussiaDeliveryCost();
		}, [
			request,
			deliveryCompanyId,
			totalOrderProductsWeight,
			postcodeValue,
			onChangeDeliveryCost,
		]);

		const changeSelectedRegionCodeHandler = (
			newValue: SingleValue<{
				value: number;
				label: string;
			}>,
		) => {
			if (!newValue) return;

			const { value, label } = newValue;

			setSelectedCityCode(null);
			setSelectedCityName(null);
			setSelectedOfficeCode(null);

			citySelectRef.current?.clearValue();
			officeSelectRef.current?.clearValue();

			setCityTouched(false);
			setOfficeTouched(false);

			setCities([]);
			setOffices([]);

			setSelectedRegionCode(value);
			setSelectedRegionName(label);
		};

		const changeSelectedCityCodeHandler = (
			newValue: SingleValue<{
				value: number;
				label: string;
			}>,
		) => {
			if (!newValue) return;

			const { value, label } = newValue;

			setSelectedCityCode(value);
			setSelectedCityName(label);
		};

		const changeSelectedOfficeHandler = (
			newValue: SingleValue<{
				value: string;
				label: string;
			}>,
		) => {
			if (!newValue) return;

			const { value } = newValue;

			const foundOffice = offices.find((office) => office.code === value);

			setSelectedOfficeCode(value);
			if (foundOffice) {
				setDeliveryPointCode(foundOffice.code);
				setDeliveryPointAddress(foundOffice.address);
			}
		};

		const closeErrorNotificationHandler = useCallback(() => {
			setErrorNotificationVisible(false);
		}, []);

		const makeOrderHandler = async (event: FormEvent<HTMLFormElement>) => {
			event.preventDefault();

			onFirstNameTouch();
			onLastNameTouch();
			onPhoneTouch();
			onEmailTouch();
			setRegionTouched(true);
			setCityTouched(true);
			setOfficeTouched(true);
			onPostcodeTouch();
			onAddressTouch();

			let formIsInvalid = true;

			if (isCdekOfficeDelivery) {
				formIsInvalid =
					!selectedRegionCode ||
					!selectedCityCode ||
					!selectedCityName ||
					!selectedOfficeCode ||
					!deliveryPointCode;
			} else if (isCdekCourierDelivery) {
				formIsInvalid =
					!selectedRegionCode ||
					!selectedCityCode ||
					!selectedCityName ||
					addressInvalid;
			} else if (isPostRussiaDelivery) {
				formIsInvalid = addressInvalid || postcodeInvalid;
			}

			if (formIsInvalid || (!authenticated ? userDataInvalid : false)) return;

			const excludedPhoneValueArr = phoneValue.match(/\d+/g);

			const phone = excludedPhoneValueArr ? excludedPhoneValueArr.join("") : "";

			let userInfo = {
				firstName: firstNameValue,
				lastName: lastNameValue,
				email: emailValue,
				phone: phone,
			};

			if (authenticated && userData) {
				userInfo = {
					firstName: userData.fname,
					lastName: userData.lname,
					email: userData.email,
					phone: userData.phone,
				};
			}

			const isLocalDelivery = selectedCityCode
				? LOCAL_DELIVERY_CITY_CODES.includes(selectedCityCode)
				: false;

			const totaOrderCost = +totalCost.toLocaleString("en", {
				useGrouping: false,
				minimumFractionDigits: 2,
			});

			onChangeProcessingOrder({ processing: true });

			const result = await request({
				service: () =>
					makeOrder({
						token: authToken,
						orderData: {
							paymentType: paymentOptionId,
							deliveryType: isLocalDelivery ? 1 : 2,
							deliveryCompanyType: deliveryCompanyId,
							deliveryRegion: selectedRegionName ? selectedRegionName : "",
							deliveryCity: selectedCityName ? selectedCityName : "",
							deliveryPostcode: postcodeValue,
							deliveryAddress: addressValue,
							deliveryPointCode: deliveryPointCode ? deliveryPointCode : "",
							deliveryPointAddress: deliveryPointAddress
								? deliveryPointAddress
								: "",
							deliveryReceiptType: deliveryTypeId,
							deliveryCost: isFreeDelivery ? 0 : deliveryCost,
							discountTotal: 100 - 100 * maxDiscount,
							discountCost: discountSum,
							totalCost: totaOrderCost,
							promocode,
							weight: totalOrderProductsWeight,
							cdekCityCode: selectedCityCode ? selectedCityCode : 0,
							comment: commentValue,
							...userInfo,
							cartProducts,
						},
					}),
			});

			if (result && isSuccessCardOrderPredicate(result)) {
				const paymentUrl = result.data.payment.formUrl;

				location.replace(paymentUrl);
				return;
			}
			if (result && isSuccessOrderPredicate(result)) {
				const orderId = result.data.id;

				scrollTo({ top: 0, behavior: "smooth" });
				navigate("/orderSuccess?orderId=" + orderId, { replace: true });
				onChangeProcessingOrder({ processing: false });
				return;
			}

			onChangeProcessingOrder({ processing: false });

			onChangePromocode({ code: "" });
			onChangePromocodeDiscount({ discount: 0 });

			navigate("/orderFail", { replace: true });
		};

		const resetFieldsHandler = () => {
			onPostcodeValueChange("");
			onAddressValueChange("");
			onCommentValueChange("");
		};

		const resetSelectsHandler = () => {
			setSelectedCityCode(null);
			setSelectedRegionCode(null);
			setSelectedRegionName(null);
			setSelectedOfficeCode(null);
			setCityTouched(false);
			setRegionTouched(false);
			setOfficeTouched(false);
		};

		const unauthorizedContent = (
			<>
				<Input
					id="firstName"
					type="text"
					classes={cn("popup-form__input", firstNameErrorVisible && "invalid")}
					placeholder=""
					labelText="Имя"
					value={firstNameValue}
					invalid={firstNameErrorVisible}
					errors={[]}
					onChangeValue={onFirstNameValueChange}
					onBlur={onFirstNameBlur}
				/>
				<Input
					id="lastName"
					type="text"
					classes={cn("popup-form__input", lastNameErrorVisible && "invalid")}
					placeholder=""
					labelText="Фамилия"
					value={lastNameValue}
					invalid={lastNameErrorVisible}
					errors={[]}
					onChangeValue={onLastNameValueChange}
					onBlur={onLastNameBlur}
				/>

				<div
					className={cn("popup-form__valid", phoneErrorVisible && "invalid")}
				>
					<label htmlFor={"phone"} className="popup-form__label">
						Телефон
					</label>
					<InputMask
						mask="+7 999 999-99-99"
						id={"phone"}
						type={"text"}
						placeholder="+7 ___ ___-__-__"
						className={cn("popup-form__input", phoneErrorVisible && "invalid")}
						value={phoneValue}
						onChange={(event) => onPhoneValueChange(event.target.value)}
						onBlur={onPhoneBlur}
					/>
				</div>
				{/* <Input
						id="phone"
						type="number"
						classes="popup-form__input"
						placeholder=""
						labelText="Телефон"
						value={phoneValue}
						invalid={false}
						errors={[]}
						onChange={onPhoneValueChange}
					/> */}

				<Input
					id="email"
					type="text"
					classes={cn("popup-form__input", emailErrorVisible && "invalid")}
					placeholder=""
					labelText="E-mail"
					value={emailValue}
					invalid={emailErrorVisible}
					errors={[]}
					onChangeValue={onEmailValueChange}
					onBlur={onEmailBlur}
				/>
			</>
		);

		const cdekGeneralContent = (
			<>
				<div className="popup-form__valid">
					<label className="popup-form__label">Регион</label>
					<ReactSelect
						className={cn(
							"product-select",
							!selectedRegionCode && regionTouched && "invalid",
						)}
						classNamePrefix="product-selecast"
						components={{ IndicatorSeparator: () => null }}
						isSearchable={true}
						options={formattedRegions}
						onChange={changeSelectedRegionCodeHandler}
						placeholder="Выбрать..."
						noOptionsMessage={() => "Ничего не найдено"}
						onBlur={() => setRegionTouched(true)}
						ref={regionSelectRef}
					/>
				</div>
				<div className="popup-form__valid invalid">
					<label className="popup-form__label">Город</label>
					<ReactSelect
						className={cn(
							"product-select",
							!selectedCityCode && cityTouched && "invalid",
						)}
						classNamePrefix="product-selecast"
						components={{ IndicatorSeparator: () => null }}
						isSearchable={true}
						isDisabled={cities.length === 0}
						options={formattedCities}
						onChange={changeSelectedCityCodeHandler}
						placeholder="Выбрать..."
						noOptionsMessage={() => "Ничего не найдено"}
						onBlur={() => setCityTouched(true)}
						ref={citySelectRef}
					/>
					{serverValidationErrors.errors["cdekCity"]?.map((error, index) => (
						<span key={"cdekCity" + index} className="popup-form__invalid">
							Ошибка: {error}
						</span>
					))}
				</div>
				{/* <div className="popup-form__valid">
					<label className="popup-form__label">Офис</label>
					<ReactSelect
						className={cn(
							"product-select",
							!selectedOfficeCode && officeTouched && "invalid",
						)}
						classNamePrefix="product-selecast"
						components={{ IndicatorSeparator: () => null }}
						isSearchable={true}
						isDisabled={offices.length === 0}
						options={formattedOficces}
						onChange={changeSelectedOfficeHandler}
						placeholder="Выбрать..."
						noOptionsMessage={() => "Ничего не найдено"}
						onBlur={() => setCityTouched(true)}
					/>
				</div> */}
			</>
		);

		const cdekOfficeContent = (
			<div className="popup-form__valid">
				<label className="popup-form__label">Офис</label>
				<ReactSelect
					className={cn(
						"product-select",
						!selectedOfficeCode && officeTouched && "invalid",
					)}
					classNamePrefix="product-selecast"
					components={{ IndicatorSeparator: () => null }}
					isSearchable={true}
					isDisabled={offices.length === 0}
					options={formattedOficces}
					onChange={changeSelectedOfficeHandler}
					placeholder="Выбрать..."
					noOptionsMessage={() => "Ничего не найдено"}
					onBlur={() => setCityTouched(true)}
					ref={officeSelectRef}
				/>
			</div>
		);

		const addressContent = (
			<Input
				id="address"
				type="text"
				classes={cn("popup-form__input", addressErrorVisible && "invalid")}
				placeholder=""
				labelText="Адрес"
				value={addressValue}
				invalid={addressErrorVisible}
				errors={[]}
				onChangeValue={onAddressValueChange}
				onBlur={onAddressBlur}
			/>
		);

		return (
			<>
				<div className="cart">
					<div className="cart__head">
						<div className="section-title">
							<span className="section-title__text">Доставка</span>
							<span className="section-title__num">01</span>
						</div>
					</div>
					<div className="cart__account">
						<form
							className="popup-form"
							ref={formRef}
							onSubmit={makeOrderHandler}
						>
							<OptionsTab
								listKey="delivery-type"
								options={deliveryReceiptTypes}
								activeIndex={deliveryTypeId}
								onChangeActiveIndex={(id: number) => {
									onChangeDeliveryTypeId({ id });
									resetFieldsHandler();
								}}
							/>
							<div className="delivery-flex">
								{filteredDeliveryCompanies.map((company, index) => (
									<button
										className={cn(
											"delivery-btn",
											filteredDeliveryCompanies.length !== 1 &&
											index !== 0 &&
											"delivery-btn-last",
										)}
										type="button"
										key={"delivery-company-" + company.id}
										style={{
											color:
												deliveryCompanyId === company.id ? "#EF1D22" : "#313131",
										}}
										onClick={() => {
											onChangeDeliveryCompanyId({ id: company.id });
											resetFieldsHandler();
											resetSelectsHandler();
										}}
									>
										{company.label}
									</button>
								))}
							</div>
							{!authenticated && unauthorizedContent}
							{/* <Input
						id="city"
						type="text"
						classes="popup-form__input"
						placeholder=""
						labelText="Населенный пункт"
						value={cityValue}
						invalid={false}
						errors={[]}
						onChange={onCityValueChange}
					/> */}

							{(isCdekCourierDelivery || isCdekOfficeDelivery) &&
								cdekGeneralContent}
							{(isCdekCourierDelivery || isPostRussiaDelivery) && addressContent}
							{isCdekOfficeDelivery && cdekOfficeContent}
							{isPostRussiaDelivery && (
								<Input
									id="postcode"
									type="number"
									classes={cn(
										"popup-form__input",
										postcodeErrorVisible && "invalid",
									)}
									placeholder=""
									labelText="Почтовый индекс"
									value={postcodeValue}
									invalid={postcodeErrorVisible}
									errors={postcodeErrors}
									onChangeValue={onPostcodeValueChange}
									onBlur={onPostcodeBlur}
								/>
							)}
							{/* {isPostRussia && postRussiaContent} */}
							{/* {isCdekDelivery && cdekContent} */}
							{/* <div className="popup-form__valid">
							<label className="popup-form__label">Регион</label>
							<ReactSelect
								className={cn(
									"product-select",
									!selectedRegionCode && regionTouched && "invalid",
								)}
								classNamePrefix="product-selecast"
								components={{ IndicatorSeparator: () => null }}
								isSearchable={true}
								options={formattedRegions}
								onChange={changeSelectedRegionCodeHandler}
								placeholder="Выбрать..."
								noOptionsMessage={() => "Ничего не найдено"}
								onBlur={() => setRegionTouched(true)}
							/>
						</div>
						<div className="popup-form__valid">
							<label className="popup-form__label">Город</label>
							<ReactSelect
								className={cn(
									"product-select",
									!selectedCityCode && cityTouched && "invalid",
								)}
								classNamePrefix="product-selecast"
								components={{ IndicatorSeparator: () => null }}
								isSearchable={true}
								isDisabled={cities.length === 0}
								options={formattedCities}
								onChange={changeSelectedCityCodeHandler}
								placeholder="Выбрать..."
								noOptionsMessage={() => "Ничего не найдено"}
								onBlur={() => setCityTouched(true)}
							/>
						</div>
						<div className="popup-form__valid">
							<label className="popup-form__label">Офис</label>
							<ReactSelect
								className={cn(
									"product-select",
									!selectedCityCode && cityTouched && "invalid",
								)}
								classNamePrefix="product-selecast"
								components={{ IndicatorSeparator: () => null }}
								isSearchable={true}
								isDisabled={cities.length === 0}
								options={formattedCities}
								onChange={changeSelectedCityCodeHandler}
								placeholder="Выбрать..."
								noOptionsMessage={() => "Ничего не найдено"}
								onBlur={() => setCityTouched(true)}
							/>
						</div> */}

							{/* <Input
							id="postcode"
							type="number"
							classes={cn(
								"popup-form__input",
								postcodeErrorVisible && "invalid",
							)}
							placeholder=""
							labelText="Почтовый индекс"
							value={postcodeValue}
							invalid={postcodeErrorVisible}
							errors={[]}
							onChangeValue={onPostcodeValueChange}
							onBlur={onPostcodeBlur}
						/>
						<Input
							id="address"
							type="text"
							classes={cn(
								"popup-form__input",
								addressErrorVisible && "invalid",
							)}
							placeholder=""
							labelText="Адрес"
							value={addressValue}
							invalid={addressErrorVisible}
							errors={[]}
							onChangeValue={onAddressValueChange}
							onBlur={onAddressBlur}
						/> */}

							<Input
								id="comment"
								type="text"
								classes="popup-form__input"
								placeholder=""
								labelText="Комментарий"
								value={commentValue}
								invalid={false}
								errors={[]}
								onChangeValue={onCommentValueChange}
							/>

							<div className="product__price-line"></div>

							{/* {deliveryTypes.map((type) => (
							<div className="product__price-block" key={"delivery-" + type.id}>
								<div className="product__price-line">
									<div className="product__price-checkbox">
										<input
											type="radio"
											id={"option-delivery-" + type.id}
											value={type.id}
											checked={type.id === activeDeliveryTypeId}
											name={"delivery-type-" + type.id}
											className="product__check-input"
											onChange={() => changeDeliveryTypeHandler(type.id)}
										/>
										<label
											htmlFor={"option-delivery-" + type.id}
											className="product__check-label"
										></label>
									</div>
									<span className="product__weight">{type.label}</span>
									{type.id in DELIVERY_PRICES && (
										<span className="product__dots"></span>
									)}
									<span className="product__price">
										{type.id in DELIVERY_PRICES &&
											DELIVERY_PRICES[type.id] + " ₽"}
									</span>
								</div>
								{activeDeliveryTypeId === type.id &&
									activeDeliveryTypeId !== 3 && (
									<div className="product__price-opt">
										{deliveryReceiptTypes.map((deliveryReceiptType) => (
											<div
												key={"delivery-receipt-" + deliveryReceiptType.id}
												className="product__price-line"
											>
												<div className="product__price-checkbox">
													<input
														type="radio"
														id={
															"option-delivery-receipt-" +
																deliveryReceiptType.id
														}
														value={deliveryReceiptType.id}
														checked={
															deliveryReceiptType.id ===
																activeDeliveryReceiptTypeId
														}
														name={
															"delivery-receipt-type-" +
																deliveryReceiptType.id
														}
														className="product__check-input"
														onChange={() =>
															changeDeliveryReceiptTypeHandler(
																deliveryReceiptType.id,
															)
														}
													/>
													<label
														htmlFor={
															"option-delivery-receipt-" +
																deliveryReceiptType.id
														}
														className="product__check-label"
													></label>
												</div>
												<span className="product__weight">
													{deliveryReceiptType.label}
												</span>
											</div>
										))}
									</div>
								)}
							</div>
						))} */}
						</form>
					</div>
				</div>

				{errorNoticationVisible && serverValidationErrors.errors["postcode"] &&
					<>
						{createPortal(
							<ErrorNotification
								messages={serverValidationErrors.errors["postcode"]}
								onClose={closeErrorNotificationHandler}
							/>,
							document.body
						)}
						{createPortal(
							<Overlay />,
							document.body
						)}
					</>
				}


			</>
		);
	},
);

export { DeliveryStep };
